<template>
  <loader v-bind="{ loading }" text="Loading Locations">
    <columns>
      <column>
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for checklists...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button 
                  v-if="Object.values($route.query).length"
                  @click="clearFilters"
                  class="is-rounded">
                  <icon icon="times"/>
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div v-for="checklist in checklists.data" :key="checklist.id" class="box is-marginless">
            <columns>
              <column>
                <router-link class="has-text-weight-bold is-block" :to="{
                    name: 'checklist-manager',
                    params: {
                      checklist: checklist.uuid
                    }}">
                    {{ checklist.checklist.name }}
                </router-link>
                <small v-if="checklist.work_order" class="has-text-grey">
                  Active {{ checklist.updated_at | asCalendar }} on <router-link :to="{
                  name: 'work-order-manager',
                  params: {
                    workOrder: checklist.work_order_uuid
                  }
                }">Work Order #{{ checklist.work_order.number }}</router-link>
                </small>
              </column>
              <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <inspectable-name
                  with-icon
                  :type="checklist.inspectable_type"
                  :inspectable="checklist.inspectable"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <checklist-images-count
                  :answers="checklist.answers"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar
                  tooltip="Current progress for this checklist"
                  :total="checklist.answers.length"
                  :total-complete="checklist.answers.filter(answer => answer.complete).length"
                  backgroundColor="#212127"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <outcome-tag 
                  :status="checklist.status"
                />
              </column>
            </columns>
          </div>
        </div>
      </column>
    </columns>
    <pager 
      v-if="checklists.data.length" 
      :pageable="checklists" 
      context="Checklist" 
      jump-controls 
      @nav="goToPage" 
    />

    <no-items-to-display
      :item-count="checklists.data.length"
      heading="There are no checklists available for display."
    />
  </loader>
</template>
<script>
import { get } from '@/api/request'

export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    checklists: {
      data: []
    },
  }),

  created() {
    if(this.$route.query.search) {
      this.filters.search = this.$route.query.search
    }
    this.loadChecklists()
  },

  methods: {
    loadChecklists() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.checklists = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    checklistProgressPercentage(answers) {
      const completed = answers.filter(answer => answer.complete).length
      const total = answers.length
      if(completed === 0) return 0
      return ((completed / total) * 100).toFixed(0)
    },
    checklistProgress(answers) {
      return `${answers.filter(answer => answer.complete).length}/${answers.length}`
    },
    runSearch() {
      this.$router.push({
        name: 'user-checklists',
        query: this.filters
      })
    },
    clearFilters() {
      this.filters = {
        search: ''
      }
      this.$router.push({
        name: 'user-checklists',
      })
    }
  },

  watch: {
    '$route': 'loadChecklists'
  }

}
</script>